import {
    CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST,
    CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_SUCCESS,
    CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_FAILURE,
} from '../../actions/actionTypes'

import {
    ClinicAdminForgotPasswordRequestPayload,
    ClinicAdminForgotPasswordRequestSuccessPayload,
    ClinicAdminForgotPasswordFailurePayload,
    ClinicAdminForgotPasswordRequest,
    ClinicAdminForgotPasswordRequestSuccess,
    ClinicAdminForgotPasswordRequestFailure
} from '../../schema/auth/forgotPasswordSchema'

export const clinicAdminForgotPasswordRequest = (payload: ClinicAdminForgotPasswordRequestPayload): ClinicAdminForgotPasswordRequest => ({
    type: CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST,
    payload,
});

export const clinicAdminForgotPasswordRequestSuccess = (payload: ClinicAdminForgotPasswordRequestSuccessPayload): ClinicAdminForgotPasswordRequestSuccess => ({
    type: CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_SUCCESS,
    payload,
});

export const clinicAdminForgotPasswordRequestFailure = (payload: ClinicAdminForgotPasswordFailurePayload): ClinicAdminForgotPasswordRequestFailure => ({
    type: CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_FAILURE,
    payload,
});
