import {
    CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST,
    CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_SUCCESS,
    CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_FAILURE,
} from '../../actions/actionTypes'
import {
    ClinicAdminForgotPasswordInterface,
    clinicAdminForgotPasswordActions,
} from '../../schema/auth/forgotPasswordSchema'

const initialState: ClinicAdminForgotPasswordInterface = {
    forgotPasswordLoader: false,
    forgotPasswordData: null,
    forgotPasswordError: ""
}
const clinicAdminForgotPasswordReducer = (
    state = initialState,
    action: clinicAdminForgotPasswordActions
) => {
    switch (action.type) {
        case CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                forgotPasswordLoader: true,
            };
        case CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                forgotPasswordLoader: false,
                forgotPasswordData: action.payload,
                forgotPasswordError: null,
            };
        case CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                forgotPasswordLoader: false,
                forgotPasswordData: null,
                forgotPasswordError: action.payload.forgotPasswordError,
            };
        default:
            return {
                ...state
            };
    }
}
export default clinicAdminForgotPasswordReducer