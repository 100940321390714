// Sign up Action types
export const CLINIC_ADMIN_SIGNUP_REQUEST = 'CLINIC_ADMIN_SIGNUP_REQUEST'
export const CLINIC_ADMIN_SIGNUP_REQUEST_SUCCESS = 'CLINIC_ADMIN_SIGNUP_REQUEST_SUCCESS'
export const CLINIC_ADMIN_SIGNUP_REQUEST_FAILURE = 'CLINIC_ADMIN_SIGNUP_REQUEST_FAILURE'

// verify mobile number
// -verify OTP
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST'
export const VERIFY_OTP_REQUEST_SUCCESS = 'VERIFY_OTP_REQUEST_SUCCESS'
export const VERIFY_OTP_REQUEST_FAILURE = 'VERIFY_OTP_REQUEST_FAILURE'

// - send OTP
export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST'
export const SEND_OTP_REQUEST_SUCCESS = 'SEND_OTP_REQUEST_SUCCESS'
export const SEND_OTP_REQUEST_FAILURE = 'SEND_OTP_REQUEST_FAILURE'
// Forgot Password
export const CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST = 'CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST'
export const CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_SUCCESS = 'CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_SUCCESS'
export const CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_FAILURE = 'CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_FAILURE'

// Reset Password
export const CLINIC_ADMIN_RESET_PASSWORD_REQUEST = 'CLINIC_ADMIN_RESET_PASSWORD_REQUEST'
export const CLINIC_ADMIN_RESET_PASSWORD_REQUEST_SUCCESS = 'CLINIC_ADMIN_RESET_PASSWORD_REQUEST_SUCCESS'
export const CLINIC_ADMIN_RESET_PASSWORD_REQUEST_FAILURE = 'CLINIC_ADMIN_RESET_PASSWORD_REQUEST_FAILURE'

// verified Email
export const VERIFIED_EMAIL_REQUEST = 'VERIFIED_EMAIL_REQUEST'
export const VERIFIED_EMAIL_REQUEST_SUCCESS = 'VERIFIED_EMAIL_REQUEST_SUCCESS'
export const VERIFIED_EMAIL_REQUEST_FAILURE = 'VERIFIED_EMAIL_REQUEST_FAILURE'

// send verification link
export const SEND_EMAIL_VERIFICATION_REQUEST = 'SEND_EMAIL_VERIFICATION_REQUEST'
export const SEND_EMAIL_VERIFICATION_REQUEST_SUCCESS = 'SEND_EMAIL_VERIFICATION_REQUEST_SUCCESS'
export const SEND_EMAIL_VERIFICATION_REQUEST_FAILURE = 'SEND_EMAIL_VERIFICATION_REQUEST_FAILURE'

// Login
export const CLINIC_ADMIN_LOGIN_REQUEST = 'CLINIC_ADMIN_LOGIN_REQUEST'
export const CLINIC_ADMIN_LOGIN_REQUEST_SUCCESS = 'CLINIC_ADMIN_LOGIN_REQUEST_SUCCESS'
export const CLINIC_ADMIN_LOGIN_REQUEST_FAILURE = 'CLINIC_ADMIN_LOGIN_REQUEST_FAILURE'

// Create password
export const CREATE_PASSWORD_REQUEST = 'CREATE_PASSWORD_REQUEST'
export const CREATE_PASSWORD_REQUEST_SUCCESS = 'CREATE_PASSWORD_REQUEST_SUCCESS'
export const CREATE_PASSWORD_REQUEST_FAILURE = 'FORGOT_PASSWORD_REQUEST_FAILURE'
export const UPDATE_CREATE_PASSWORD_DATA = 'UPDATE_CREATE_PASSWORD_DATA'
export const UPDATE_CREATE_PASSWORD_ERROR = 'UPDATE_CREATE_PASSWORD_ERROR'

// Change password action types
export const CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST = 'CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST'
export const CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_SUCCESS = 'CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_SUCCESS'
export const CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_FAILURE = 'CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_FAILURE'

// Get owner profile
export const GET_OWNER_PROFILE_INFO_REQUEST = 'GET_OWNER_PROFILE_INFO_REQUEST'
export const GET_OWNER_PROFILE_INFO_REQUEST_SUCCESS = 'GET_OWNER_PROFILE_INFO_REQUEST_SUCCESS'
export const GET_OWNER_PROFILE_INFO_REQUEST_FAILURE = 'GET_OWNER_PROFILE_INFO_REQUEST_FAILURE'
export const GET_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST = 'GET_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST'

// EDIT owner profile
export const EDIT_OWNER_PROFILE_INFO_REQUEST = 'EDIT_OWNER_PROFILE_INFO_REQUEST'
export const EDIT_OWNER_PROFILE_INFO_REQUEST_SUCCESS = 'EDIT_OWNER_PROFILE_INFO_REQUEST_SUCCESS'
export const EDIT_OWNER_PROFILE_INFO_REQUEST_FAILURE = 'EDIT_OWNER_PROFILE_INFO_REQUEST_FAILURE'
export const EDIT_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST = 'EDIT_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST'

// Terms-and-condition and Privacy-policy
export const TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST = 'TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST'
export const TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_SUCCESS = 'TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_SUCCESS'
export const TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_FAILURE = 'TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_FAILURE'

// Get Terms-and-condition and Privacy-policy
export const GET_TC_REQUEST = 'GET_TC_REQUEST'
export const GET_TC_REQUEST_SUCCESS = 'GET_TC_REQUEST_SUCCESS'
export const GET_TC_REQUEST_FAILURE = 'GET_TC_REQUEST_FAILURE'

// create Clinic
export const CREATE_CLINIC_REQUEST = 'CREATE_CLINIC_REQUEST'
export const CREATE_CLINIC_REQUEST_SUCCESS = 'CREATE_CLINIC_REQUEST_SUCCESS'
export const CREATE_CLINIC_REQUEST_FAILURE = 'CREATE_CLINIC_REQUEST_FAILURE'

// get Clinic all
export const GET_CLINIC_REQUEST = 'GET_CLINIC_REQUEST'
export const GET_CLINIC_REQUEST_SUCCESS = 'GET_CLINIC_REQUEST_SUCCESS'
export const GET_CLINIC_REQUEST_FAILURE = 'GET_CLINIC_REQUEST_FAILURE'
export const GET_CLINIC_CACHE_DATA_CLEAR_REQUEST = 'GET_CLINIC_CACHE_DATA_CLEAR_REQUEST'

// view clinic details
export const VIEW_CLINIC_REQUEST = 'VIEW_CLINIC_REQUEST'
export const VIEW_CLINIC_REQUEST_SUCCESS = 'VIEW_CLINIC_REQUEST_SUCCESS'
export const VIEW_CLINIC_REQUEST_FAILURE = 'VIEW_CLINIC_REQUEST_FAILURE'
export const VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST = 'VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST'

// update clinic
export const UPDATE_CLINIC_REQUEST = 'UPDATE_CLINIC_REQUEST'
export const UPDATE_CLINIC_REQUEST_SUCCESS = 'UPDATE_CLINIC_REQUEST_SUCCESS'
export const UPDATE_CLINIC_REQUEST_FAILURE = 'UPDATE_CLINIC_REQUEST_FAILURE'

// Delete Clinic
export const DELETE_CLINIC_REQUEST = 'DELETE_CLINIC_REQUEST'
export const DELETE_CLINIC_REQUEST_SUCCESS = 'DELETE_CLINIC_REQUEST_SUCCESS'
export const DELETE_CLINIC_REQUEST_FAILURE = 'DELETE_CLINIC_REQUEST_FAILURE'

// update status (clinic)
export const UPDATE_CLINIC_STATUS_REQUEST = 'UPDATE_CLINIC_STATUS_REQUEST'
export const UPDATE_CLINIC_STATUS_REQUEST_SUCCESS = 'UPDATE_CLINIC_STATUS_REQUEST_SUCCESS'
export const UPDATE_CLINIC_STATUS_REQUEST_FAILURE = 'UPDATE_CLINIC_STATUS_REQUEST_FAILURE'

// update status in local
export const UPDATE_CLINIC_STATUS_LOCAL = 'UPDATE_CLINIC_STATUS_LOCAL'

// Patient module
// create patient
export const CREATE_PATIENT_REQUEST = 'CREATE_PATIENT_REQUEST'
export const CREATE_PATIENT_REQUEST_SUCCESS = 'CREATE_PATIENT_REQUEST_SUCCESS'
export const CREATE_PATIENT_REQUEST_FAILURE = 'CREATE_PATIENT_REQUEST_FAILURE'

// get Patient all
export const GET_PATIENT_REQUEST_SUCCESS = 'GET_PATIENT_REQUEST_SUCCESS'
export const GET_PATIENT_REQUEST = 'GET_PATIENT_REQUEST'
export const GET_PATIENT_REQUEST_FAILURE = 'GET_PATIENT_REQUEST_FAILURE'
export const GET_PATIENT_CACHE_DATA_CLEAR_REQUEST = 'GET_PATIENT_CACHE_DATA_CLEAR_REQUEST'
export const OPEN_POPUP_PATIENT_MODE = 'OPEN_POPUP_PATIENT_MODE'

// view Patient details
export const VIEW_PATIENT_REQUEST = 'VIEW_PATIENT_REQUEST'
export const VIEW_PATIENT_REQUEST_SUCCESS = 'VIEW_PATIENT_REQUEST_SUCCESS'
export const VIEW_PATIENT_REQUEST_FAILURE = 'VIEW_PATIENT_REQUEST_FAILURE'
export const VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST = 'VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST'

// update Patient
export const UPDATE_PATIENT_REQUEST = 'UPDATE_PATIENT_REQUEST'
export const UPDATE_PATIENT_REQUEST_SUCCESS = 'UPDATE_PATIENT_REQUEST_SUCCESS'
export const UPDATE_PATIENT_REQUEST_FAILURE = 'UPDATE_PATIENT_REQUEST_FAILURE'

// Delete Patient
export const DELETE_PATIENT_REQUEST = 'DELETE_PATIENT_REQUEST'
export const DELETE_PATIENT_REQUEST_SUCCESS = 'DELETE_PATIENT_REQUEST_SUCCESS'
export const DELETE_PATIENT_REQUEST_FAILURE = 'DELETE_PATIENT_REQUEST_FAILURE'

// update status (Patient
export const UPDATE_PATIENT_STATUS_REQUEST = 'UPDATE_PATIENT_STATUS_REQUEST'
export const UPDATE_PATIENT_STATUS_REQUEST_SUCCESS = 'UPDATE_PATIENT_STATUS_REQUEST_SUCCESS'
export const UPDATE_PATIENT_STATUS_REQUEST_FAILURE = 'UPDATE_PATIENT_STATUS_REQUEST_FAILURE'

// update status in local
export const UPDATE_PATIENT_STATUS_LOCAL = 'UPDATE_PATIENT_STATUS_LOCAL'

// get Member all
export const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST'
export const GET_MEMBER_REQUEST_SUCCESS = 'GET_MEMBER_REQUEST_SUCCESS'
export const GET_MEMBER_REQUEST_FAILURE = 'GET_MEMBER_REQUEST_FAILURE'
export const GET_MEMBER_CACHE_DATA_CLEAR_REQUEST = 'GET_MEMBER_CACHE_DATA_CLEAR_REQUEST'

// update status in local
export const UPDATE_MEMBER_STATUS_LOCAL = 'UPDATE_MEMBER_STATUS_LOCAL'

// create member
export const CREATE_MEMBER_REQUEST = 'CREATE_MEMBER_REQUEST'
export const CREATE_MEMBER_REQUEST_SUCCESS = 'CREATE_MEMBER_REQUEST_SUCCESS'
export const CREATE_MEMBER_REQUEST_FAILURE = 'CREATE_MEMBER_REQUEST_FAILURE'

// update status member
export const UPDATE_MEMBER_STATUS_REQUEST = 'UPDATE_MEMBER_STATUS_REQUEST'
export const UPDATE_MEMBER_STATUS_REQUEST_SUCCESS = 'UPDATE_MEMBER_STATUS_REQUEST_SUCCESS'
export const UPDATE_MEMBER_STATUS_REQUEST_FAILURE = 'UPDATE_MEMBER_STATUS_REQUEST_FAILURE'

// Delete Member
export const DELETE_MEMBER_REQUEST = 'DELETE_MEMBER_REQUEST'
export const DELETE_MEMBER_REQUEST_SUCCESS = 'DELETE_MEMBER_REQUEST_SUCCESS'
export const DELETE_MEMBER_REQUEST_FAILURE = 'DELETE_MEMBER_REQUEST_FAILURE'

// update member details
export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST'
export const UPDATE_MEMBER_REQUEST_SUCCESS = 'UPDATE_MEMBER_REQUEST_SUCCESS'
export const UPDATE_MEMBER_REQUEST_FAILURE = 'UPDATE_MEMBER_REQUEST_FAILURE'

// View member details
export const VIEW_MEMBER_REQUEST = 'VIEW_MEMBER_REQUEST'
export const VIEW_MEMBER_REQUEST_SUCCESS = 'VIEW_MEMBER_REQUEST_SUCCESS'
export const VIEW_MEMBER_REQUEST_FAILURE = 'VIEW_MEMBER_REQUEST_FAILURE'
export const VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST = 'VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST'

// Get all appointment
export const GET_APPOINTMENT_REQUEST = 'GET_APPOINTMENT_REQUEST'
export const GET_APPOINTMENT_REQUEST_SUCCESS = 'GET_APPOINTMENT_REQUEST_SUCCESS'
export const GET_APPOINTMENT_REQUEST_FAILURE = 'GET_APPOINTMENT_REQUEST_FAILURE'
export const GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST = 'GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST'

// create appointment
export const CREATE_APPOINTMENT_REQUEST = 'CREATE_APPOINTMENT_REQUEST'
export const CREATE_APPOINTMENT_REQUEST_SUCCESS = 'CREATE_APPOINTMENT_REQUEST_SUCCESS'
export const CREATE_APPOINTMENT_REQUEST_FAILURE = 'CREATE_APPOINTMENT_REQUEST_FAILURE'

// update appointment
export const UPDATE_APPOINTMENT_REQUEST = 'UPDATE_APPOINTMENT_REQUEST'
export const UPDATE_APPOINTMENT_REQUEST_SUCCESS = 'UPDATE_APPOINTMENT_REQUEST_SUCCESS'
export const UPDATE_APPOINTMENT_REQUEST_FAILURE = 'UPDATE_APPOINTMENT_REQUEST_FAILURE'

// view appointment
export const VIEW_APPOINTMENT_REQUEST = 'VIEW_APPOINTMENT_REQUEST'
export const VIEW_APPOINTMENT_REQUEST_SUCCESS = 'VIEW_APPOINTMENT_REQUEST_SUCCESS'
export const VIEW_APPOINTMENT_REQUEST_FAILURE = 'VIEW_APPOINTMENT_REQUEST_FAILURE'
export const VIEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST = 'VIEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST'

// For set data from get api in edit-member
export const UPDATE_ASSIGN_ROLE_TO_MEMBER_REQUEST = 'UPDATE_ASSIGN_ROLE_TO_MEMBER_REQUEST'
// For clinic => member => edit ----------  edit-member => edit roles
export const POST_EDIT_MEMBER_ROLE_REQUEST = 'POST_EDIT_MEMBER_ROLE_REQUEST'

// Get PMS software
export const GET_PMS_SOFTWARE_REQUEST = 'GET_PMS_SOFTWARE_REQUEST'
export const GET_PMS_SOFTWARE_REQUEST_SUCCESS = 'GET_PMS_SOFTWARE_REQUEST_SUCCESS'
export const GET_PMS_SOFTWARE_REQUEST_FAILURE = 'GET_PMS_SOFTWARE_REQUEST_FAILURE'

// Get all clinic
export const GET_ALL_CLINIC_REQUEST = 'GET_ALL_CLINIC_REQUEST'
export const GET_ALL_CLINIC_REQUEST_SUCCESS = 'GET_ALL_CLINIC_REQUEST_SUCCESS'
export const GET_ALL_CLINIC_REQUEST_FAILURE = 'GET_ALL_CLINIC_REQUEST_FAILURE'

// Edit role to member
export const EDIT_ROLE_TO_MEMBER_REQUEST = 'EDIT_ROLE_TO_MEMBER_REQUEST'
export const EDIT_ROLE_TO_MEMBER_REQUEST_SUCCESS = 'EDIT_ROLE_TO_MEMBER_REQUEST_SUCCESS'
export const EDIT_ROLE_TO_MEMBER_REQUEST_FAILURE = 'EDIT_ROLE_TO_MEMBER_REQUEST_FAILURE'

// get modules
export const GET_MODULES_REQUEST = 'GET_MODULES_REQUEST'
export const GET_MODULES_REQUEST_SUCCESS = 'GET_MODULES_REQUEST_SUCCESS'
export const GET_MODULES_REQUEST_FAILURE = 'GET_MODULES_REQUEST_FAILURE'

// get clinic by staff
export const GET_CLINIC_STAFF_REQUEST = 'GET_CLINIC_STAFF_REQUEST'
export const GET_CLINIC_STAFF_REQUEST_SUCCESS = 'GET_CLINIC_STAFF_REQUEST_SUCCESS'
export const GET_CLINIC_STAFF_REQUEST_FAILURE = 'GET_CLINIC_STAFF_REQUEST_FAILURE'

// match password
export const MATCH_PASSWORD_REQUEST = 'MATCH_PASSWORD_REQUEST'
export const MATCH_PASSWORD_REQUEST_SUCCESS = 'MATCH_PASSWORD_REQUEST_SUCCESS'
export const MATCH_PASSWORD_REQUEST_FAILURE = 'MATCH_PASSWORD_REQUEST_FAILURE'
// get all member
export const GET_ALL_MEMBER_REQUEST = 'GET_ALL_MEMBER_REQUEST'
export const GET_ALL_MEMBER_REQUEST_SUCCESS = 'GET_ALL_MEMBER_REQUEST_SUCCESS'
export const GET_ALL_MEMBER_REQUEST_FAILURE = 'GET_ALL_MEMBER_REQUEST_FAILURE'
// appointment local data manage
export const UPDATE_APPOINTMENT_INFO = 'UPDATE_APPOINTMENT_INFO'
export const UPDATE_APPOINTMENT_INFO_FAILURE = 'UPDATE_APPOINTMENT_INFO_FAILURE'
export const CHANGE_APPOINTMENT_INFO = 'CHANGE_APPOINTMENT_INFO'

// get all patient
export const GET_ALL_PATIENT_REQUEST = 'GET_ALL_PATIENT_REQUEST'
export const GET_ALL_PATIENT_REQUEST_SUCCESS = 'GET_ALL_PATIENT_REQUEST_SUCCESS'
export const GET_ALL_PATIENT_REQUEST_FAILURE = 'GET_ALL_PATIENT_REQUEST_FAILURE'

// get staff data by clinic
export const GET_ALL_STAFF_BY_CLINIC_REQUEST = 'GET_ALL_STAFF_BY_CLINIC_REQUEST'
export const GET_ALL_STAFF_BY_CLINIC_REQUEST_SUCCESS = 'GET_ALL_STAFF_BY_CLINIC_REQUEST_SUCCESS'
export const GET_ALL_STAFF_BY_CLINIC_REQUEST_FAILURE = 'GET_ALL_STAFF_BY_CLINIC_REQUEST_FAILURE'

// clinic list for the filter
export const GET_CLINIC_FILTER_LIST_REQUEST = 'GET_CLINIC_FILTER_LIST_REQUEST'
export const GET_CLINIC_FILTER_LIST_REQUEST_SUCCESS = 'GET_CLINIC_FILTER_LIST_REQUEST_SUCCESS'
export const GET_CLINIC_FILTER_LIST_REQUEST_FAILURE = 'GET_CLINIC_FILTER_LIST_REQUEST_FAILURE'

// approve clinic
export const APPROVE_CLINIC_REQUEST = 'APPROVE_CLINIC_REQUEST'
export const APPROVE_CLINIC_SUCCESS = 'APPROVE_CLINIC_SUCCESS'
export const APPROVE_CLINIC_FAILURE = 'APPROVE_CLINIC_FAILURE'
export const APPROVE_CLINIC_FINISHED = 'APPROVE_CLINIC_FINISHED'

// clinic subscription
export const GET_SUBSCRIPTION_PLANS_REQUEST = 'GET_SUBSCRIPTION_PLANS_REQUEST'
export const GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS = 'GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS'
export const GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE = 'GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE'
export const GET_CARDS_REQUEST = 'GET_CARDS_REQUEST'
export const GET_CARDS_REQUEST_SUCCESS = 'GET_CARDS_REQUEST_SUCCESS'
export const GET_CARDS_REQUEST_FAILURE = 'GET_CARDS_REQUEST_FAILURE'
export const ADD_CARD_REQUEST = 'ADD_CARD_REQUEST'
export const SELECT_CARD_REQUEST = 'SELECT_CARD_REQUEST'

// remove card

export const REMOVE_CARD_REQUEST = 'REMOVE_CARD_REQUEST'
export const REMOVE_CARD_REQUEST_SUCCESS = 'REMOVE_CARD_REQUEST_SUCCESS'
export const REMOVE_CARD_REQUEST_FAILURE = 'REMOVE_CARD_REQUEST_FAILURE'

// update payment method
export const UPDATE_PAYMENT_METHOD_REQUEST = 'UPDATE_PAYMENT_METHOD_REQUEST'
export const UPDATE_PAYMENT_METHOD_REQUEST_SUCCESS = 'UPDATE_PAYMENT_METHOD_REQUEST_SUCCESS'
export const UPDATE_PAYMENT_METHOD_REQUEST_FAILURE = 'UPDATE_PAYMENT_METHOD_REQUEST_FAILURE'

// selectedPlanId
export const SELECT_SUBSCRIPTION_PLANS_ID = 'SELECT_SUBSCRIPTION_PLANS_ID'

// unsubscribe
export const UNSUBSCRIBE_REQUEST = 'UNSUBSCRIBE_REQUEST'
export const UNSUBSCRIBE_REQUEST_SUCCESS = 'UNSUBSCRIBE_REQUEST_SUCCESS'
export const UNSUBSCRIBE_REQUEST_FAILURE = 'UNSUBSCRIBE_REQUEST_FAILURE'
// get subscription plan history
export const GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST = 'GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST'
export const GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_SUCCESS = 'GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_SUCCESS'
export const GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_FAILURE = 'GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_FAILURE'

// for store the cookies value
export const SAVE_COOKIES_REQUEST = 'SAVE_COOKIES_REQUEST'

// update device token for the notification
export const UPDATE_DEVICE_TOKEN = 'UPDATE_DEVICE_TOKEN'
// checkout
export const UPDATE_PREAUTH_CHECKLIST_DATA = 'UPDATE_PREAUTH_CHECKLIST_DATA'

// get checkout data
export const GET_CHECKOUT_DATA_REQUEST = 'GET_CHECKOUT_DATA_REQUEST'
export const GET_CHECKOUT_DATA_REQUEST_SUCCESS = 'GET_CHECKOUT_DATA_REQUEST_SUCCESS'
export const GET_CHECKOUT_DATA_REQUEST_FAILURE = 'GET_CHECKOUT_DATA_REQUEST_FAILURE'

// get provider data
export const GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST = 'GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST'
export const GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_SUCCESS = 'GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_SUCCESS'
export const GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_FAILURE = 'GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_FAILURE'

// update checkout data
export const PUT_CHECKOUT_DATA_REQUEST = 'PUT_CHECKOUT_DATA_REQUEST'
export const PUT_CHECKOUT_DATA_REQUEST_SUCCESS = 'PUT_CHECKOUT_DATA_REQUEST_SUCCESS'
export const PUT_CHECKOUT_DATA_REQUEST_FAILURE = 'PUT_CHECKOUT_DATA_REQUEST_FAILURE'

// get procedure code via clinic
export const GET_PROCEDURE_CODE_REQUEST = 'GET_PROCEDURE_CODE_REQUEST'
export const GET_PROCEDURE_CODE_REQUEST_SUCCESS = 'GET_PROCEDURE_CODE_REQUEST_SUCCESS'
export const GET_PROCEDURE_CODE_REQUEST_FAILURE = 'GET_PROCEDURE_CODE_REQUEST_FAILURE'
export const GET_PROCEDURE_CODE_CACHE_DATA_CLEAR_REQUEST = 'GET_PROCEDURE_CODE_CACHE_DATA_CLEAR_REQUEST'

export const GET_SELECTED_PROCEDURE_DATA = 'GET_SELECTED_PROCEDURE_DATA'

// update procedure data locally
export const UPDATE_PROCEDURE_DATA_LOCALLY = 'UPDATE_PROCEDURE_DATA_LOCALLY'

// update procedure data validation
export const UPDATE_PROCEDURE_DATA_VALIDATIONS = 'UPDATE_PROCEDURE_DATA_VALIDATIONS'

// update clinic specific procedure data locally
export const UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_LOCALLY = 'UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_LOCALLY'

// update clinic specific procedure data validation
export const UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_VALIDATIONS = 'UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_VALIDATIONS'

// update procedure data
export const PUT_PROCEDURE_DATA_REQUEST = 'PUT_PROCEDURE_DATA_REQUEST'
export const PUT_PROCEDURE_DATA_REQUEST_SUCCESS = 'PUT_PROCEDURE_DATA_REQUEST_SUCCESS'
export const PUT_PROCEDURE_DATA_REQUEST_FAILURE = 'PUT_PROCEDURE_DATA_REQUEST_FAILURE'

export const UPDATE_HYGIENE_REGIME_DATA = 'UPDATE_HYGIENE_REGIME_DATA'

export const UPDATE_INSURANCE_TREATMENT_DATA = 'UPDATE_INSURANCE_TREATMENT_DATA'

export const GET_APPOINTMENT_COUNT_REQUEST = 'GET_APPOINTMENT_COUNT_REQUEST'
export const GET_APPOINTMENT_COUNT_REQUEST_SUCCESS = 'GET_APPOINTMENT_COUNT_REQUEST_SUCCESS'
export const GET_APPOINTMENT_COUNT_REQUEST_FAILURE = 'GET_APPOINTMENT_COUNT_REQUEST_FAILURE'

// Dashboard
export const SET_DESCENDING_ASCENDING = 'SET_DESCENDING_ASCENDING'

// Appointment
export const SET_DESCENDING_ASCENDING_APPOINTMENT = 'SET_DESCENDING_ASCENDING_APPOINTMENT'

// manage Owners
export const GET_OWNER_LIST_REQUEST = 'GET_OWNER_LIST_REQUEST'
export const GET_OWNER_LIST_REQUEST_SUCCESS = 'GET_OWNER_LIST_REQUEST_SUCCESS'
export const GET_OWNER_LIST_REQUEST_FAILURE = 'GET_OWNER_LIST_REQUEST_FAILURE'

export const CREATE_OWNER_REQUEST = 'CREATE_OWNER_REQUEST'
export const CREATE_OWNER_REQUEST_SUCCESS = 'CREATE_OWNER_REQUEST_SUCCESS'
export const CREATE_OWNER_REQUEST_FAILURE = 'CREATE_OWNER_REQUEST_FAILURE'

export const UPDATE_OWNER_REQUEST = 'UPDATE_OWNER_REQUEST'
export const UPDATE_OWNER_REQUEST_SUCCESS = 'UPDATE_OWNER_REQUEST_SUCCESS'
export const UPDATE_OWNER_REQUEST_FAILURE = 'UPDATE_OWNER_REQUEST_FAILURE'

export const GET_OWNER_DETAILS_REQUEST = 'GET_OWNER_DETAILS_REQUEST'
export const GET_OWNER_DETAILS_REQUEST_SUCCESS = 'GET_OWNER_DETAILS_REQUEST_SUCCESS'
export const GET_OWNER_DETAILS_REQUEST_FAILURE = 'GET_OWNER_DETAILS_REQUEST_FAILURE'

export const DELETE_OWNER_REQUEST = 'DELETE_OWNER_REQUEST'
export const DELETE_OWNER_REQUEST_SUCCESS = 'DELETE_OWNER_REQUEST_SUCCESS'
export const DELETE_OWNER_REQUEST_FAILURE = 'DELETE_OWNER_REQUEST_FAILURE'

export const CHANGE_OWNER_DETAILS = 'CHANGE_OWNER_DETAILS'
export const UPDATE_OWNER_ERROR = 'UPDATE_OWNER_ERROR'
export const UPDATE_OWNER_DETAILS_STATE = 'UPDATE_OWNER_DETAILS_STATE'

// download csv file
export const DOWNLOAD_CSV_FILE_REQUEST = 'DOWNLOAD_CSV_FILE_REQUEST'
export const DOWNLOAD_CSV_FILE_REQUEST_SUCCESS = 'DOWNLOAD_CSV_FILE_REQUEST_SUCCESS'
export const DOWNLOAD_CSV_FILE_REQUEST_FAIL = 'DOWNLOAD_CSV_FILE_REQUEST_FAIL'

// upload csv file
export const UPLOAD_CSV_FILE_REQUEST = 'UPLOAD_CSV_FILE_REQUEST'
export const UPLOAD_CSV_FILE_REQUEST_SUCCESS = 'UPLOAD_CSV_FILE_REQUEST_SUCCESS'
export const UPLOAD_CSV_FILE_REQUEST_FAIL = 'UPLOAD_CSV_FILE_REQUEST_FAIL'

// Verify Email for User already exist
export const VERIFY_USER_EMAIL_REQUEST = 'VERIFY_USER_EMAIL_REQUEST'
export const VERIFY_USER_EMAIL_REQUEST_SUCCESS = 'VERIFY_USER_EMAIL_REQUEST_SUCCESS'
export const VERIFY_USER_EMAIL_REQUEST_FAIL = 'VERIFY_USER_EMAIL_REQUEST_FAIL'

export const UPDATE_CHECKOUT_VALIDATIONS = 'UPDATE_CHECKOUT_VALIDATIONS'

// QSurvey page
export const CHANGE_PRACTICE_INFORMATION = 'CHANGE_PRACTICE_INFORMATION'

export const UPDATE_PRACTICE_INFORMATION_VALIDATION = 'UPDATE_PRACTICE_INFORMATION_VALIDATION'

export const GET_PRACTICE_INFORMATION_REQUEST = 'GET_PRACTICE_INFORMATION_REQUEST'
export const GET_PRACTICE_INFORMATION_REQUEST_SUCCESS = 'GET_PRACTICE_INFORMATION_REQUEST_SUCCESS'
export const GET_PRACTICE_INFORMATION_REQUEST_FAIL = 'GET_PRACTICE_INFORMATION_REQUEST_FAIL'

export const PUT_PRACTICE_INFORMATION_REQUEST = 'PUT_PRACTICE_INFORMATION_REQUEST'
export const PUT_PRACTICE_INFORMATION_REQUEST_SUCCESS = 'PUT_PRACTICE_INFORMATION_REQUEST_SUCCESS'
export const PUT_PRACTICE_INFORMATION_REQUEST_FAIL = 'PUT_PRACTICE_INFORMATION_REQUEST_FAIL'

// MSA Agreement
export const CHANGE_MSA_AGREEMENT = 'CHANGE_MSA_AGREEMENT'

export const UPDATE_MSA_AGREEMENT_VALIDATION = 'UPDATE_MSA_AGREEMENT_VALIDATION'

export const PUT_MSA_AGREEMENT_REQUEST = 'PUT_MSA_AGREEMENT_REQUEST'
export const PUT_MSA_AGREEMENT_REQUEST_SUCCESS = 'PUT_MSA_AGREEMENT_REQUEST_SUCCESS'
export const PUT_MSA_AGREEMENT_REQUEST_FAIL = 'PUT_MSA_AGREEMENT_REQUEST_FAIL'

export const GET_MSA_AGREEMENT_REQUEST = 'GET_MSA_AGREEMENT_REQUEST'
export const GET_MSA_AGREEMENT_REQUEST_SUCCESS = 'GET_MSA_AGREEMENT_REQUEST_SUCCESS'
export const GET_MSA_AGREEMENT_REQUEST_FAIL = 'GET_MSA_AGREEMENT_REQUEST_FAIL'

export const SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST = 'SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST'
export const SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_SUCCESS = 'SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_SUCCESS'
export const SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_FAIL = 'SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_FAIL'

// pre-intake start

export const UPDATE_PATIENT_INFO = 'UPDATE_PATIENT_INFO'
export const UPDATE_DENTIST_INSURANCE_INFO = 'UPDATE_DENTIST_INSURANCE_INFO'
export const UPDATE_EMERGENCY_CONTACT_INFO = 'UPDATE_EMERGENCY_CONTACT_INFO'
export const UPDATE_GENERAL_HEALTH = 'UPDATE_GENERAL_HEALTH'
export const UPDATE_SLEEP_QUALITY = 'UPDATE_SLEEP_QUALITY'
export const UPDATE_MEDICAL_HEALTH_HISTORY = 'UPDATE_MEDICAL_HEALTH_HISTORY'
export const UPDATE_LETS_GET_TO_KNOW_YOU_BETTER = 'UPDATE_LETS_GET_TO_KNOW_YOU_BETTER'
export const UPDATE_DENTAL_INFO = 'UPDATE_DENTAL_INFO'
export const UPDATE_COMMUNICATION_PREFERENCES = 'UPDATE_COMMUNICATION_PREFERENCES'

/*Get pre-intake api*/
export const GET_PRE_INTAKE_DATA_REQUEST = 'GET_PRE_INTAKE_DATA_REQUEST'
export const GET_PRE_INTAKE_DATA_SUCCESS = 'GET_PRE_INTAKE_DATA_SUCCESS'
export const GET_PRE_INTAKE_DATA_FAIL = 'GET_PRE_INTAKE_DATA_FAIL'

/*Put pre-intake api*/
export const PUT_PRE_INTAKE_DATA_REQUEST = 'PUT_PRE_INTAKE_DATA_REQUEST'
export const PUT_PRE_INTAKE_DATA_SUCCESS = 'PUT_PRE_INTAKE_DATA_SUCCESS'
export const PUT_PRE_INTAKE_DATA_FAIL = 'PUT_PRE_INTAKE_DATA_FAIL'

/*pre-intake validations*/
export const UPDATE_PRE_INTAKE_VALIDATIONS = 'UPDATE_PRE_INTAKE_VALIDATIONS'

export const IS_PRE_INTAKE_SUBMIT = 'IS_PRE_INTAKE_SUBMIT'

/*Download pre-intake*/
export const DOWNLOAD_PRE_INTAKE_PDF_REQUEST = 'DOWNLOAD_PRE_INTAKE_PDF_REQUEST'
export const DOWNLOAD_PRE_INTAKE_PDF_SUCCESS = 'DOWNLOAD_PRE_INTAKE_PDF_SUCCESS'
export const DOWNLOAD_PRE_INTAKE_PDF_FAIL = 'DOWNLOAD_PRE_INTAKE_PDF_FAIL'
// pre-intake End

// risk score
export const GET_WELLNESS_REQUEST = 'GET_WELLNESS_REQUEST'
export const GET_WELLNESS_REQUEST_SUCCESS = 'GET_WELLNESS_REQUEST_SUCCESS'
export const GET_WELLNESS_REQUEST_FAILURE = 'GET_WELLNESS_REQUEST_FAILURE'

// clinic settings
export const UPDATE_CLINIC_SETTINGS = 'UPDATE_CLINIC_SETTINGS'
export const PUT_CLINIC_SETTINGS_REQUEST = 'PUT_CLINIC_SETTINGS_REQUEST'
export const PUT_CLINIC_SETTINGS_REQUEST_SUCCESS = 'PUT_CLINIC_SETTINGS_REQUEST_SUCCESS'
export const PUT_CLINIC_SETTINGS_REQUEST_FAILURE = 'PUT_CLINIC_SETTINGS_REQUEST_FAILURE'
export const UPDATE_CLINIC_SETTINGS_VALIDATIONS = 'UPDATE_CLINIC_SETTINGS_VALIDATIONS'

// get field for pre-intake
export const GET_FIELDS_REQUEST = 'GET_FIELDS_REQUEST'
export const GET_FIELDS_REQUEST_SUCCESS = 'GET_FIELDS_REQUEST_SUCCESS'
export const GET_FIELDS_REQUEST_FAIL = 'GET_FIELDS_REQUEST_FAIL'

// communication
export const COMMUNICATIONS_REQUEST = 'COMMUNICATIONS_REQUEST'
export const COMMUNICATIONS_REQUEST_SUCCESS = 'COMMUNICATIONS_REQUEST_SUCCESS'
export const COMMUNICATIONS_REQUEST_FAIL = 'COMMUNICATIONS_REQUEST_FAIL'

// update communication in local for patient
export const UPDATE_PATIENT_COMMUNICATION_LOCAL = 'UPDATE_PATIENT_COMMUNICATION_LOCAL'
