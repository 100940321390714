import {
  CREATE_PATIENT_REQUEST,
  CREATE_PATIENT_REQUEST_FAILURE,
  CREATE_PATIENT_REQUEST_SUCCESS,
  DELETE_PATIENT_REQUEST,
  DELETE_PATIENT_REQUEST_FAILURE,
  DELETE_PATIENT_REQUEST_SUCCESS,
  GET_PATIENT_CACHE_DATA_CLEAR_REQUEST,
  GET_PATIENT_REQUEST,
  GET_PATIENT_REQUEST_FAILURE,
  GET_PATIENT_REQUEST_SUCCESS,
  GET_WELLNESS_REQUEST,
  GET_WELLNESS_REQUEST_FAILURE,
  GET_WELLNESS_REQUEST_SUCCESS,
  OPEN_POPUP_PATIENT_MODE,
  SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST,
  SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_FAIL,
  SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_SUCCESS,
  UPDATE_PATIENT_COMMUNICATION_LOCAL,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_REQUEST_FAILURE,
  UPDATE_PATIENT_REQUEST_SUCCESS,
  UPDATE_PATIENT_STATUS_LOCAL,
  UPDATE_PATIENT_STATUS_REQUEST,
  UPDATE_PATIENT_STATUS_REQUEST_FAILURE,
  UPDATE_PATIENT_STATUS_REQUEST_SUCCESS,
  VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST,
  VIEW_PATIENT_REQUEST,
  VIEW_PATIENT_REQUEST_FAILURE,
  VIEW_PATIENT_REQUEST_SUCCESS,
} from '../../actions/actionTypes'
import { createPatientActions, PatientInterface } from '../../schema/patient/patientSchema'

const initialState: PatientInterface = {
  createPatientLoader: false,
  createPatientData: null,
  createPatientError: null,
  getPatientLoader: false,
  getPatientData: null,
  getPatientError: null,
  viewPatientLoader: false,
  viewPatientData: null,
  viewPatientError: null,
  updatePatientLoader: false,
  updatePatientData: null,
  updatePatientError: null,
  deletePatientLoader: false,
  deletePatientData: null,
  deletePatientError: null,
  patientStatusUpdateLoader: false,
  patientStatusUpdateData: null,
  patientStatusUpdateError: null,
  openPopupPatientMode: false,
  sendPatientPreIntakeReminderLoader: false,
  sendPatientPreIntakeReminderData: null,
  sendPatientPreIntakeReminderError: null,
  wellnessProfileLoader: false,
  wellnessProfileData: null,
  wellnessProfileError: null,
}
const patientReducer = (state = initialState, action: createPatientActions) => {
  switch (action.type) {
    case CREATE_PATIENT_REQUEST:
      return {
        ...state,
        createPatientLoader: true,
      }
    case CREATE_PATIENT_REQUEST_SUCCESS:
      return {
        ...state,
        createPatientLoader: false,
        createPatientData: action.payload,
        createPatientError: null,
      }
    case CREATE_PATIENT_REQUEST_FAILURE:
      return {
        ...state,
        createPatientLoader: false,
        createPatientData: null,
        createPatientError: action.payload.createPatientError,
      }
    case GET_PATIENT_REQUEST:
      return {
        ...state,
        getPatientLoader: true,
      }
    case GET_PATIENT_REQUEST_SUCCESS:
      return {
        ...state,
        getPatientLoader: false,
        getPatientData: action.payload?.getPatientData,
        getPatientMeta: action.payload?.getPatientMeta,
        getPatientError: null,
      }
    case GET_PATIENT_REQUEST_FAILURE:
      return {
        ...state,
        getPatientLoader: false,
        getPatientData: null,
        getPatientError: action.payload.getPatientError,
      }
    case UPDATE_PATIENT_STATUS_LOCAL:
      let getPatient = state.getPatientData
      getPatient = getPatient?.map((res: any, i: number) => {
        if (i === action?.payload?.index) {
          return {
            ...res,
            status: res?.status === 'Active' ? 'Inactive' : 'Active',
          }
        }
        return res
      })
      return {
        ...state,
        getPatientData: getPatient,
      }

    case GET_PATIENT_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getPatientData: null,
      }
    case VIEW_PATIENT_REQUEST:
      return {
        ...state,
        viewPatientLoader: true,
      }
    case VIEW_PATIENT_REQUEST_SUCCESS:
      return {
        ...state,
        viewPatientLoader: false,
        viewPatientData: action.payload,
        viewPatientError: null,
      }
    case VIEW_PATIENT_REQUEST_FAILURE:
      return {
        ...state,
        viewPatientLoader: false,
        viewPatientData: null,
        viewPatientError: action.payload.viewPatientError,
      }
    case VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        viewPatientData: null,
      }
    case UPDATE_PATIENT_REQUEST:
      return {
        ...state,
        updatePatientLoader: true,
      }
    case UPDATE_PATIENT_REQUEST_SUCCESS:
      return {
        ...state,
        updatePatientLoader: false,
        updatePatientData: action.payload,
        updatePatientError: null,
      }
    case UPDATE_PATIENT_REQUEST_FAILURE:
      return {
        ...state,
        updatePatientLoader: false,
        updatePatientData: null,
        updatePatientError: action.payload.updatePatientError,
      }
    case DELETE_PATIENT_REQUEST:
      return {
        ...state,
        deletePatientLoader: true,
      }
    case DELETE_PATIENT_REQUEST_SUCCESS:
      return {
        ...state,
        deletePatientLoader: false,
        deletePatientError: null,
      }
    case DELETE_PATIENT_REQUEST_FAILURE:
      return {
        ...state,
        deletePatientLoader: false,
        deletePatientData: null,
        deletePatientError: action.payload.deletePatientError,
      }
    case UPDATE_PATIENT_STATUS_REQUEST:
      return {
        ...state,
        patientStatusUpdateLoader: true,
      }
    case UPDATE_PATIENT_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        patientStatusUpdateLoader: false,
        patientStatusUpdateData: action.payload,
        patientStatusUpdateError: null,
      }
    case UPDATE_PATIENT_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        patientStatusUpdateLoader: false,
        patientStatusUpdateData: null,
        patientStatusUpdateError: action.payload.patientStatusUpdateError,
      }
    case OPEN_POPUP_PATIENT_MODE:
      return {
        ...state,
        openPopupPatientMode: action.payload,
      }
    case SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST:
      return {
        ...state,
        sendPatientPreIntakeReminderLoader: true,
      }
    case SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_SUCCESS:
      return {
        ...state,
        sendPatientPreIntakeReminderLoader: false,
        sendPatientPreIntakeReminderData: action.payload,
        sendPatientPreIntakeReminderError: null,
      }
    case SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_FAIL:
      return {
        ...state,
        sendPatientPreIntakeReminderLoader: false,
        sendPatientPreIntakeReminderData: null,
        sendPatientPreIntakeReminderError: action.payload,
      }
    case GET_WELLNESS_REQUEST:
      return {
        ...state,
        wellnessProfileLoader: true,
        wellnessProfileData: null,
        wellnessProfileError: null,
      }
    case GET_WELLNESS_REQUEST_SUCCESS:
      return {
        ...state,
        wellnessProfileLoader: false,
        wellnessProfileData: action.payload,
        wellnessProfileError: null,
      }

    case GET_WELLNESS_REQUEST_FAILURE:
      return {
        ...state,
        wellnessProfileLoader: false,
        wellnessProfileData: null,
        wellnessProfileError: action.payload,
      }
    case UPDATE_PATIENT_COMMUNICATION_LOCAL:
      let getPatientDetails = state.getPatientData
      getPatientDetails = getPatientDetails?.map((res: any, i: number) => {
        if (i === action?.payload?.index) {
          return {
            ...res,
            communication_preferences: !res?.communication_preferences,
          }
        }
        return res
      })
      return {
        ...state,
        getPatientData: getPatientDetails,
      }
    default:
      return {
        ...state,
      }
  }
}
export default patientReducer
